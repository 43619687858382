html {
  position: relative;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 12px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f0f0 none repeat scroll 0 0;
  /* Margin bottom by footer height */
  margin-bottom: 80px;
}

.bodyimage {
  background: url(/return/Images/bg_contact_big.jpg) repeat scroll center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
}

th {
  background-image: linear-gradient(180deg, #f3f3f3 0%, #e8e8e8 100%);
  padding: 5px;
}
.table-row-disable {
  background: #dedede;
}
tr {
  border: 1px solid #dedede;
}
tr.disabled {
  color: #7c7b7b;
  background: #dedede;
}

td {
  padding: 5px;
}

hr {
  border: 0;
  background-color: #eee;
  height: 1px;
}

.bold {
  font-weight: bold;
}

.btn-primary {
  background-image: -webkit-linear-gradient(
    180deg,
    rgb(63, 159, 219) 0%,
    rgb(40, 140, 203) 100%
  );
  background-image: -webkit-linear-gradient(
    180deg,
    rgb(63, 159, 219) 0%,
    rgb(40, 140, 203) 100%
  );
}

.btn-primary:hover {
  box-shadow: none;
  color: #fff;
  background: linear-gradient(180deg, #3f9fdb 0%, #288ccb 100%);
}

.headerBar {
  background-color: #2489c8 !important;
  background-image: linear-gradient(to bottom, #42a1dd 0px, #2489c8 100%);
  height: 60px;
}

.footer {
  background-color: #2489c8 !important;
  background-image: linear-gradient(to bottom, #42a1dd 0px, #2489c8 100%);
  height: 80px;
  position: absolute;
  bottom: 0px;
  color: #fff;
  font-size: 14px;
}

.footer-noflow {
  background-color: #2489c8 !important;
  background-image: linear-gradient(to bottom, #42a1dd 0px, #2489c8 100%);
  height: 80px;
  bottom: 0;
  color: #fff;
  font-size: 14px;
}

:disabled {
  cursor: not-allowed;
}

.loading {
  background-color: black;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  position: absolute;
  padding-top: 30%;
  padding-left: 47%;
  z-index: 1;
  font-size: 24px;
  color: #fff;
}
.alignCenter {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.alignRight {
  text-align: end;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.button {
  color: #333;
  font-size: 12px;
}

.contactButton {
  color: #fff;
  font-weight: bold;
}

.order-note {
  border-bottom: 1px dashed #ddd;
  margin-top: 8px;
  margin-bottom: 8px;
}

/*RetOrderStatusId	Code
1	OPEN
2	ACCEPTED
3	REFUNDED
4	DECLINED
5	PROCESSING
6	CLOSED
7	CANCELED
8	AUTHORIZED
9	REVISION
10	REPLACEMENT_SENT
11	APPROVED
12	ADVANCE_REPLACEMENT
13	EXTRA_PACKAGE
14	SEND_INVOICE
15	INVOICE_SENT*/
.activity-btn-1 {
  background: #4283c5;
}
.activity-btn-2 {
  background: #32cb3a;
}
.activity-btn-3 {
  background: #42c4c0;
}
.activity-btn-4 {
  background: #f43a0c;
}
.activity-btn-5 {
  background: #32cb3a;
}
.activity-btn-6 {
  background: #000000;
}
.activity-btn-7 {
  background: #f43a0c;
}
.activity-btn-8 {
  background: #32cb3a;
}
.activity-btn-9 {
  background: #32cb3a;
}
.activity-btn-10 {
  background: #cb77e5;
}
.activity-btn-11 {
  background: #32cb3a;
}
.activity-btn-12 {
  background: #ea98a6;
}
.activity-btn-13 {
  background: #4283c5;
}
.activity-btn-14 {
  background: #4283c5;
}
.activity-btn-15 {
  background: #4283c5;
}

.activityButton {
  width: 100%;
}

.btn-sm {
  height: 25px;
  font-size: 10pt;
  padding: 2px 5px;
  margin-top: 2px;
}

.navbar {
  border-right: 1px solid #e8e8e8;
  text-transform: uppercase;
}

.inline {
  display: inline-block;
}
.img-responsive {
  max-width: 230px;
  padding-bottom: 10px;
}

.whiteContainer {
  background-color: #fff;
}

.whiteContainerHeader {
  background-color: #fff;
}

.paddingBefore10 {
  padding-top: 10px;
}

.paddingBefore20 {
  padding-top: 20px;
}

.paddingAfter20 {
  padding-bottom: 20px;
}

.font-size14 {
  color: #000;
  font-size: 14px;
}

.font-size18 {
  color: #000;
  font-size: 18px;
  line-height: 23px;
}

.font-size22 {
  color: #000;
  font-size: 22px;
  line-height: 27px;
  font-weight: bold;
}

.font-size48 {
  color: #000;
  font-size: 48px;
  line-height: 27px;
  font-weight: bold;
}

.text-white {
  color: #fff;
}

.text-red {
  color: #f92b17;
  font-weight: bold;
  font-size: 15px;
}

.link-white {
  color: #fff;
  text-decoration: underline;
}
.link-white:hover {
  color: #fff;
}

.asterisk-red {
  color: #a94442;
}

.jumbotron {
  background: url(/return/Images/sign-upBg.png) repeat;
  padding: 30px;
  overflow: hidden;
  border-radius: 5px;
}

.checkboxLabel {
  font-weight: bold;
  font-size: 14px;
}

.return-description {
  width: 100%;
  max-width: 687px;
  margin: 35px auto 40px;
  padding: 5px 60px 17px;
  box-shadow: 0 0 6px 0 rgba(199, 199, 199, 0.5);
}

.form-control {
  width: 100%;
}

.thumbnail {
  height: 66px;
  width: 79px;
}

.product-box {
  z-index: 1;
  display: inline-table;
  position: relative;
  padding-top: 10px;
  padding-right: 5px;
}

.product-img-box {
  z-index: 1;
  border: 1px solid #ddd;
  display: inline-table;
  height: 66px;
  width: 79px;
  position: relative;
}

.product-thumbnail .fa-picture {
  z-index: 1;
  color: #9c9c9c;
  display: inline-block;
  font-size: 24px;
  /* padding: 20px 25px; */
  position: absolute;
  left: 40%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  vertical-align: middle;
  white-space: nowrap;
}

.vertical-middle {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.width250 {
  width: 250px;
  padding-left: 115px;
}

.paddingLeft75 {
  padding-left: 75px;
}

input[type="file"] {
  display: none;
}

.imageButton {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.choice-status label {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  color: #7c7b7b;
  font-size: 12px;
  line-height: 10px;
  cursor: pointer;
  font-weight: bold;
  text-align: left;
  padding: 11px 15px 10px 36px;
}
.choice-status.replacement {
  max-width: initial;
  display: block;
}
.choice-status label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: -1;
  border: 1px solid #dddddd;
  border-radius: 4px;
}

.choice-status input:checked ~ label:after {
  background: linear-gradient(180deg, #3f9fdb 0%, #288ccb 100%);
  background: -webkit-linear-gradient(180deg, #3f9fdb 0%, #288ccb 100%);
  border-color: #288ccb;
}

.choice-status input:checked ~ label {
  color: #fff;
}

.choice-status input:disabled ~ label {
  cursor: no-drop;
}
.choice-status {
  display: inline-block;
  height: 32px;
  position: relative;
  max-width: 181px;
  margin: 0;
  z-index: 0;
}

.choice-status input {
  position: absolute;

  opacity: 0;
}

.control-indicator {
  position: absolute;
  top: 8px;
  height: 16px;
  left: 12px;
  width: 16px;
  background: #fff;
  border: 1px solid #dddddd;
}
.choice-status .control-indicator {
  border-radius: 50%;
}
.choice-status input:checked ~ label .control-indicator {
  background: #fff;
  border: 1px solid transparent;
}
.control-indicator:after {
  content: "";
  position: absolute;
  display: none;
}
.choice-status input:checked ~ label .control-indicator:after {
  display: block;
}
.choice-status .control-indicator:after {
  border-radius: 50%;
  height: 6px;
  left: 4px;
  top: 4px;
  width: 6px;
  background: #288ccb;
}

.modalImage {
  height: auto;
  width: 100%;
}

.popover {
  max-width: 800px;
  width: auto;
}

/* Mobile responsive */

@media (max-width: 491px) {
  .mobileResponsiveMarginFix {
    margin-bottom: 240px;
  }
}

/* 
.col-1 {
  background-color: aqua;
}
.col-2 {
  background-color: blueviolet;
}
.col-4 {
  background-color: coral;
} */
